import type {  MetaFunction } from "@remix-run/cloudflare";
import { motion } from "framer-motion";
import { Button } from "~/components/ui/button";
import { Card, CardContent } from "~/components/ui/card";
import { Navigation } from "~/components/layout/Navigation";

export const meta: MetaFunction = () => {
  return [
    { title: "Everliquid" },
    {
      name: "description",
      content: "Maximize returns from idle cash with Everliquid.",
    },
  ];
};

export default function Index() {
  return (
    <>
      <Navigation />
      <div className="container max-w-7xl mx-auto">
        <div className="space-y-8 p-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl font-bold">Unlock Hidden Returns from Your Idle Cash</h1>
            <p className="text-lg mt-4">
              Maximize your income with intelligent treasury management tailored for SMEs.
            </p>
            <Button asChild className="mt-6">
              <a href="#">Get Started Today</a>
            </Button>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h2 className="text-3xl font-semibold">Is Your Idle Cash Working Hard Enough?</h2>
            <p className="text-lg mt-4">
              As a small or medium-sized enterprise, you might be sitting on substantial liquid cash
              without realizing its full potential. <strong>Everliquid</strong> transforms your idle
              funds into profitable investments, boosting your income without the need for a
              sophisticated treasury department.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h2 className="text-3xl font-semibold">How Everliquid Works</h2>
            <Card className="mt-4 p-4 space-y-4">
              <CardContent>
                <p className="text-lg">
                  <strong>1. Continuous Financial Analysis</strong>
                  <br />
                  We continuously analyze your balance sheets and income statements to understand your
                  cash flow and liquidity needs.
                </p>
                <p className="text-lg">
                  <strong>2. Smart Investment Recommendations</strong>
                  <br />
                  Get tailored advice on how much you can safely invest, for how long, and at what
                  yield—without impacting your operational needs.
                </p>
                <p className="text-lg">
                  <strong>3. Access Exclusive Investment Opportunities</strong>
                  <br />
                  Unlock higher-risk, higher-return private credit instruments typically reserved for
                  larger corporations.
                </p>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <h2 className="text-3xl font-semibold">Why Choose Everliquid?</h2>
            <Card className="mt-4 p-4 space-y-4">
              <CardContent>
                <p className="text-lg">
                  <strong>Maximize Returns</strong>
                  <br />
                  Turn idle cash into a new revenue stream, earning returns you never thought possible.
                </p>
                <p className="text-lg">
                  <strong>Tailored for SMEs</strong>
                  <br />
                  Designed specifically for businesses without dedicated treasury teams.
                </p>
                <p className="text-lg">
                  <strong>Risk Management</strong>
                  <br />
                  Invest confidently with recommendations based on your financial health.
                </p>
                <p className="text-lg">
                  <strong>User-Friendly Platform</strong>
                  <br />
                  Easy-to-use interface makes treasury management accessible to everyone.
                </p>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            <h2 className="text-3xl font-semibold">Boost Your Income Today</h2>
            <p className="text-lg mt-4">
              Don't let your cash sit idle. Start maximizing your returns now.
            </p>
            <Button asChild className="mt-6">
              <a href="#">Get Started Now</a>
            </Button>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.0 }}
          >
            <h2 className="text-3xl font-semibold">Frequently Asked Questions</h2>
            <Card className="mt-4 p-4 space-y-4">
              <CardContent>
                <p className="text-lg">
                  <strong>Q:</strong> <em>Is Everliquid safe to use?</em>
                  <br />
                  <strong>A:</strong> Absolutely. We prioritize your financial security and provide
                  recommendations based on thorough analyses.
                </p>
                <p className="text-lg">
                  <strong>Q:</strong> <em>Do I need prior treasury management experience?</em>
                  <br />
                  <strong>A:</strong> Not at all. Everliquid simplifies treasury management, making it
                  easy regardless of your background.
                </p>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.2 }}
          >
            <h2 className="text-3xl font-semibold">Get in Touch</h2>
            <p className="text-lg mt-4">
              Have questions?{" "}
              <a href="#" className="text-primary">
                Contact us
              </a>{" "}
              to learn more about how Everliquid can benefit your business.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1.4 }}
          >
            <p className="text-sm mt-8">
              © {new Date().getFullYear()} Everliquid |{" "}
              <a href="#" className="text-primary">
                Privacy Policy
              </a>{" "}
              |{" "}
              <a href="#" className="text-primary">
                Terms of Service
              </a>
            </p>
          </motion.div>
        </div>
      </div>
    </>
  );
}
